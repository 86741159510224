import React, { useCallback, useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"

import { Helmet } from "react-helmet"
import * as styles from "./CheckoutHistoryContent.module.css"
import { Container, Row, Col, Button, Table } from "react-bootstrap"
import ReactTooltip from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faCheck, faClock, faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import log from "../../components/log"
import formatNumber from "../../components/checkout/formatNumber"
import LoadingContent from "../../components/content-checkout-history/LoadingContent"
import ReactTimeAgo from "react-time-ago"
import useInterval from "use-interval"
import useAuth from "../../hooks/useAuth"
import { LoadingPageOnly } from "../../components/auth/LoadingPage"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"


const CheckoutHistoryContent = () => {
  /**
   * Constant
   */
  const isDebug = (process.env.APP_DEBUG == 'true')
  const { isAuthenticated, isLoading } = useAuth()
  const axiosPrivate = useAxiosPrivate()

  /**
   * API Fetch state
   */
  const [isGettingLoading, setIsGettingLoading] = React.useState(true)
  const [isGettingReady, setIsGettingReady] = React.useState(false)
  const [isGettingSuccess, setIsGettingSuccess] = React.useState(true)

  const [checkoutHistory, setCheckoutHistory] = React.useState([])

  const updateInterval = 30 // sec
  const [nextUpdateTimestamp, setNextUpdateTimestamp] = React.useState(Date.now() / 1000)


  /**
   * Load data
   */
  function loadData() {
    /**
     * Update next update timestamp
     */
    setNextUpdateTimestamp(prev => prev + updateInterval)

    /**
     * Get list from server
     */

    setIsGettingLoading(true)
    setIsGettingReady(false)


    const getLists = async () => {
      let endpoint = "/api/checkout/list"
      if (isDebug) {
        log("debug", "Calling API.", {
          endpoint: endpoint,
        })
      }

      try {
        const res = await axiosPrivate.get(endpoint)

        if (res.status === 200) {
          setCheckoutHistory(res.data.data.list)
          setIsGettingSuccess(true)
        } else {
          setIsGettingSuccess(false)
        }

        setIsGettingReady(true)
        setIsGettingLoading(false)
      } catch (err: any) {
        if (err?.response == undefined) {
          log("error", "Gagal terhubung ke server, silahkan muat ulang halaman.", {
            file: 'CheckoutHistoryContent',
            func: 'loadData',
            exception: err,
          })
        } else {
          // Ignore token expired error
          if (err?.response?.status != 401) {
            log("error", "Gagal saat mengambil data dari server, silahkan muat ulang halaman. Jika masih mengalami kendala silahkan login ulang.", {
              endpoint: endpoint,
              error: err,
            })
          }
        }
      }
    }
    getLists()

  }

  /**
   * Do something on load
   */
  React.useEffect(() => {
    loadData()
  }, [])

  useInterval(() => {
    loadData()
  }, updateInterval * 1000)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Riwayat Pembelian</title>
      </Helmet>

      {isLoading && (
        <>
          <LoadingPageOnly />
        </>
      )}
      {isAuthenticated && (
        <>
          <Container className={styles.section}>
            <Row>
              <Col md="6">
                <h1>Riwayat Pembelian</h1>
              </Col>
              <Col md="6" className={`${styles.textRight} ${styles.textSmall}`}>
                Pembaharuan otomatis{" "}
                <ReactTimeAgo future date={new Date(nextUpdateTimestamp * 1000)} timeStyle="round" />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className={styles.mobileView}>
                  {isGettingLoading && !isGettingReady ? (
                    <>
                      <LoadingContent />
                    </>
                  ) : (
                    <>
                      {
                        checkoutHistory.length < 1 && (
                          <>
                            Belum ada data.
                          </>
                        )
                      }
                      <div className={styles.cardList}>
                        {checkoutHistory.map((item: CheckoutList) => {
                          // Count item 
                          let itemCount = 0
                          item.items.map((subItem: any) => {
                            if ((subItem.slug == 'pro_one_month' || subItem.slug == 'delivery_cost') == false) {
                              itemCount = itemCount + subItem.qty;
                            }
                          })

                          return (
                            <Link key={item.id} data-key={item.id} to={"/dashboard/checkout-detail/?id=" + item.id}>

                              <div className={styles.smallText}>
                                <div className={styles.left}>

                                  <ReactTimeAgo date={new Date(item.created_at * 1000)} />
                                </div>
                                <div className={styles.right}>
                                  {item.is_paid == true ? (
                                    <>
                                      <div className="text-success">
                                        <FontAwesomeIcon icon={faCheck} /> Terbayar
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {item.is_expired == true ? (
                                        <>
                                          <div className="text-danger">
                                            <FontAwesomeIcon icon={faExclamationCircle} /> Kadaluarsa
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <div className={styles.pulse}>
                                            <FontAwesomeIcon icon={faClock} /> Menunggu Pembayaran
                                            {item.is_expired == false && (
                                              <>
                                                <br />
                                                <div className={styles.textInfo}>
                                                  (Otomatis Kadaluarsa pada:{" "}
                                                  <ReactTimeAgo future date={new Date(item.expired_at * 1000)} />)
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className={styles.content}>
                                <div className={styles.title}>
                                  {item.item_type_label}
                                  {
                                    itemCount > 1 ? (
                                      <>{' - '}{itemCount} Item KTP Fisik</>
                                    ) : (
                                      <>
                                        {
                                          item.item_label && (
                                            <>
                                              {' - '}{item.item_label}
                                            </>
                                          )
                                        }
                                      </>
                                    )
                                  }
                                </div>
                                <div className={styles.footer}>
                                  <div className={styles.left}>
                                    <Button variant={"outline-primary"}>Detail</Button>
                                  </div>
                                  <div className={styles.right}>

                                    {
                                      item.total_amount != null ? (
                                        <>Total Nominal: {formatNumber(item.total_amount, 'Rp', '')}</>
                                      ) : null
                                    }

                                  </div>
                                </div>
                              </div>
                            </Link>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
                <div className={styles.desktopView}>
                  {isGettingLoading && !isGettingReady ? (
                    <>
                      <LoadingContent />
                    </>
                  ) : (
                    <>
                      <Table responsive hover>
                        <thead>
                          <tr>
                            <th>Jenis Pembelian</th>
                            <th>Dibuat</th>
                            <th>Total</th>
                            <th>Status</th>
                            <th>Detail</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            checkoutHistory.length < 1 && (
                              <>
                                <tr>
                                  <td colSpan={5}>
                                    <div className="text-center">
                                      Belum ada data.
                                    </div>
                                  </td>
                                </tr>
                              </>
                            )
                          }
                          {checkoutHistory.map((item: CheckoutList) => {

                            // Count item 
                            let itemCount = 0
                            item.items.map((subItem: any) => {
                              if ((subItem.slug == 'pro_one_month' || subItem.slug == 'delivery_cost') == false) {
                                itemCount = itemCount + subItem.qty;
                              }
                            })
                            return (
                              <tr key={item.id} data-key={item.id}>
                                <td>
                                  {item.item_type_label}
                                  {
                                    itemCount > 1 ? (
                                      <>{' - '}{itemCount} Item KTP Fisik</>
                                    ) : (
                                      <>
                                        {
                                          item.item_label && (
                                            <>
                                              {' - '}{item.item_label}
                                            </>
                                          )
                                        }
                                      </>
                                    )
                                  }
                                </td>
                                <td>
                                  <ReactTimeAgo date={new Date(item.created_at * 1000)} />
                                </td>
                                <td>{formatNumber(item.total_amount, 'Rp', '')}</td>
                                <td>
                                  {item.is_paid == true ? (
                                    <>
                                      <div className="text-success">
                                        <FontAwesomeIcon icon={faCheck} /> Terbayar
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {item.is_expired == true ? (
                                        <>
                                          <div className="text-danger">
                                            <FontAwesomeIcon icon={faExclamationCircle} /> Kadaluarsa, Silahkan Ulangi
                                            Pesanan
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <div className={styles.pulse}>
                                            <FontAwesomeIcon icon={faClock} /> Menunggu Pembayaran
                                            {item.is_expired == false && (
                                              <>
                                                <br />
                                                <div className={styles.textInfo}>
                                                  (Otomatis Kadaluarsa pada:{" "}
                                                  <ReactTimeAgo future date={new Date(item.expired_at * 1000)} />)
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>
                                  <Link to={"/dashboard/checkout-detail/?id=" + item.id}>
                                    <Button variant={"outline-primary"} className={styles.smallBtn}>
                                      Detail Pembelian
                                    </Button>
                                  </Link>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </>
                  )}
                </div>



              </Col>
            </Row>
          </Container>
        </>
      )
      }
    </>
  )
}

export default CheckoutHistoryContent
