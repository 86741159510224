import axios from "../api/axios"
import log from "../components/log"
import useAuth from "./useAuth"

const useRefreshToken = () => {

  const refresh = async () => {
    const { accessToken, setAccessToken } = useAuth()
    const response = await axios.post(
      "/api/auth/refresh_token",
      {
        headers: {
          "Content-type": "application/json",
        },
      },
      {
        withCredentials: true,
      }
    )

    log('debug', '🍪 Refreshing token ...', {
      oldToken: accessToken,
      newToken: response.data.data.access_token,
      file: 'useRefreshToken'
    })

    // Set new token
    setAccessToken(response.data.data.access_token)
    if (typeof window !== "undefined") {
      localStorage.setItem("access_token", response.data.data.access_token)
    }

    log('debug', '🍪 Current token ...', {
      currentToken: accessToken,
    })

    return response.data.data.access_token
  }
  return refresh
}

export default useRefreshToken
