import * as React from "react"
import ContentLoader from "react-content-loader"
import * as styles from "./LoadingContent.module.css"

const LoadingContent = () => {
  return (
    <>
      <div className={styles.containerDesktop}>
        <ContentLoader viewBox="0 0 1000 120" backgroundColor="#f5f5f5" foregroundColor="#dfdfdf">
          <rect x="0" y="2" rx="4" ry="4" width="100%" height="30" />

          <rect x="0" y="40" rx="4" ry="4" width="20%" height="30" />
          <rect x="21%" y="40" rx="4" ry="4" width="20%" height="30" />
          <rect x="42%" y="40" rx="4" ry="4" width="20%" height="30" />
          <rect x="63%" y="40" rx="4" ry="4" width="37%" height="30" />

          <rect x="0" y="77" rx="4" ry="4" width="20%" height="30" />
          <rect x="21%" y="77" rx="4" ry="4" width="20%" height="30" />
          <rect x="42%" y="77" rx="4" ry="4" width="20%" height="30" />
          <rect x="63%" y="77" rx="4" ry="4" width="37%" height="30" />
        </ContentLoader>
      </div>
      <div className={styles.containerMobile}>
        <ContentLoader viewBox="0 0 700 1000" backgroundColor="#f5f5f5" foregroundColor="#dfdfdf">
          <rect x="0" y="0" rx="8" ry="8" width="100%" height="240" />
          <rect x="0" y="270" rx="8" ry="8" width="100%" height="240" />
          <rect x="0" y="540" rx="8" ry="8" width="100%" height="240" />
          <rect x="0" y="810" rx="8" ry="8" width="100%" height="240" />
        </ContentLoader>
      </div>
    </>
  )
}

export default LoadingContent
