const formatNumber = (angka: number, prefix: string = "Rp", suffix: string = "") => {
  if (angka == undefined) {
    return ""
  }

  const format = angka
    .toString()
    .split("")
    .reverse()
    .join("")
  const convert = format.match(/\d{1,3}/g)
  if (convert !== null) {
    return (
      prefix +
      (angka < 0 ? "-" : "") +
      convert
        .join(",")
        .split("")
        .reverse()
        .join("") +
      suffix
    )
  }
  return angka.toString()
}

export default formatNumber
