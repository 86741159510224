import { useEffect } from "react"
import { axiosPrivate } from "../api/axios"
import log from "../components/log"
import useAuth from "./useAuth"
import useRefreshToken from "./useRefreshToken"

const useAxiosPrivate = () => {
  const refresh = useRefreshToken()
  const { accessToken } = useAuth()

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config: any) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    const responseIntercept = axiosPrivate.interceptors.response.use(
      response => response,
      async error => {
        const prevRequest = error?.config
        if (
          (
            error?.response?.status === 401 ||
            error?.response?.data?.message.toLowerCase().includes("expired") // if token expired
          )
          && !prevRequest?.sent
        ) {
          // console.log('>>> EXPIRED')
          prevRequest.sent = true
          const newAccessToken = await refresh()
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`
          log('debug', 'Retry request because previously the `access_token` is already expired.')
          return axiosPrivate(prevRequest)
        }
        return Promise.reject(error)
      }
    )

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept)
      axiosPrivate.interceptors.response.eject(responseIntercept)
    }
  }, [accessToken, refresh])

  return axiosPrivate
}

export default useAxiosPrivate
