// extracted by mini-css-extract-plugin
export var cardList = "CheckoutHistoryContent-module--cardList--bfbf3";
export var content = "CheckoutHistoryContent-module--content--f630b";
export var desktopView = "CheckoutHistoryContent-module--desktopView--f0ff4";
export var footer = "CheckoutHistoryContent-module--footer--4b4ea";
export var item = "CheckoutHistoryContent-module--item--fc25c";
export var items = "CheckoutHistoryContent-module--items--55bdc";
export var itemsHeader = "CheckoutHistoryContent-module--itemsHeader--e2a2c";
export var left = "CheckoutHistoryContent-module--left--73877";
export var mobileView = "CheckoutHistoryContent-module--mobileView--ac90a";
export var right = "CheckoutHistoryContent-module--right--f3453";
export var section = "CheckoutHistoryContent-module--section--83a07";
export var smallBtn = "CheckoutHistoryContent-module--smallBtn--669dc";
export var smallText = "CheckoutHistoryContent-module--smallText--d2a01";
export var textInfo = "CheckoutHistoryContent-module--textInfo--a3a0b";
export var textRight = "CheckoutHistoryContent-module--textRight--33ad8";
export var textSmall = "CheckoutHistoryContent-module--textSmall--a917b";
export var title = "CheckoutHistoryContent-module--title--e1776";